import { useEffect } from 'react';
import { navigate } from 'gatsby';

export default () => {
  useEffect(() => {
    const path = window.location.pathname
    const query = window.location.search
    if (path.indexOf('/@') === 0) {
      window.location.href = `https://accounts.authereum.org${path}${query}`
      return
    }

    navigate('/')
  }, []);
  return null;
};